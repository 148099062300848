@use '@angular/material' as mat;
@mixin ecab-tabs-theme($theme, $typography) {
  $background: map-get($theme, background);
  $selected-tab: mat.get-color-from-palette($background, selected-tab);

  .mat-tab-group.mat-primary .mat-ink-bar,
  .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: $selected-tab;
  }
}
