h1 {
  font-size: 36px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 21px;
}

h4 {
  font-size: 18px;
}
