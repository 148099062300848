@use './mixins' as *;

table.single-action-list {
  tbody tr {
    @include clickable-element(white, darken, 10%);
  }
}

.single-action-list .clickable-item {
  @include clickable-element(white, darken, 10%);
}
