@use 'sass:map';

// breakpoints taken from https://github.com/angular/flex-layout/wiki/Responsive-API
$breakpoints: (
  xs: 600px,
  sm: 960px,
  md: 1280px,
  lg: 1920px,
);

$limits: (
  min: true,
  max: true,
);

@mixin media-query($key, $limit: min) {
  $breakpoint: map.get($breakpoints, $key);

  @if $breakpoint == null {
    @error 'Breakpoint must be xs | sm | md | lg';
  }

  @if not(map.has-key($limits, $limit)) {
    @error 'Limit must be min | max';
  }

  @if ($limit == max) {
    @media screen and (max-width: $breakpoint - 1px) {
      @content;
    }
  } @else {
    @media screen and (min-width: $breakpoint) {
      @content;
    }
  }
}
