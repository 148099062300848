@use './utils/mediaquery' as *;
@import 'ecab-theme';
@import 'variables';
@import 'headings';

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

a[mat-button],
button {
  text-transform: uppercase;
}

.blanc {
  color: $typology-white-color;
}

.vert {
  color: $typology-green-color;
}

.rouge {
  color: $typology-red-color;
}

.to-define {
  color: $typology-to-define-color;
}

.initials {
  text-transform: uppercase;
}

span.separator {
  border-top: solid 1px $ecab-secondary-color-light-4;
  border-left: solid 1px $ecab-secondary-color-light-4;
}

mat-form-field.light {
  .mat-form-field-label {
    font-weight: 300;
    color: white !important;
  }

  .mat-select-arrow,
  .mat-select .mat-select-value-text,
  .mat-input-element {
    font-weight: 300;
    color: white;
  }

  .mat-form-field-underline {
    background-color: $ecab-secondary-color-light-2;
  }

  .mat-form-field-ripple {
    background-color: $ecab-secondary-color-light-3 !important;
  }
}

.visually-hidden {
  position: absolute !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
}

.list-540px {
  max-height: 540px !important;
}

.clickable {
  cursor: pointer;
}

.capitalized {
  text-transform: capitalize;
}

.no-border-dialog-container .mat-dialog-container {
  padding: 0;
}

.mat-tooltip {
  word-wrap: break-word;
  font-size: 12px;
  white-space: pre-line;
}

.mat-list-no-padding {
  padding: 0 !important;
}

.mat-drawer {
  .mat-list-item {
    font-size: 16px;
  }

  .mat-list-icon {
    font-size: 20px;
  }

  .active-link {
    background-color: #E4231366;
    .mat-line {
      font-weight: bold !important;
    }
  }

  .mat-list-item:hover {
    background: #e4241336;
  }
}

.address-item {
  .mat-list-item-content {
    padding: 0 !important;
  }
}

.sibat-responsive-dialog {
  @include media-query(xs, max) {
    max-width: 100% !important;
    height: 100vh;
  }
}
.mat-dialog-content {
  @include media-query(xs, max) {
    max-height: none !important;
  }
}
