@use '/src/styles/colors/ecab' as *;

/* INPUT STYLE */

input.mat-input-element {
  color: $ecab-text-color;
  font-size: 16px;
  border-color: $ecab-border-color;
}

.mat-form-field-label-wrapper {
  color: $ecab-text-color-lighter;
}

/* ERROR STYLE */

// Text below input
mat-error.mat-error {
  font-size: 14px;
  color: $ecab-warning-color;
}

// Input style
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid {
  .mat-form-field-outline-thick {
    color: transparent;
    border-radius: 4px;
    border-bottom: 5px solid $ecab-warning-color;
  }

  .mat-form-field-outline-end {
    border: 1px solid $ecab-border-color;
    border-left: none;
    border-bottom: none;
    border-bottom-right-radius: 0;
  }

  .mat-form-field-outline-gap {
    border: none;
    border-top: 1px solid $ecab-border-color;
  }

  &.mat-focused {
    .mat-form-field-outline-gap {
      border-top: none;
    }
  }

  .mat-form-field-outline-start {
    border: 1px solid $ecab-border-color;
    border-right: none;
    border-bottom: none;
    border-bottom-left-radius: 0;
  }
}

/* DISABLED STYLE */

.mat-form-field-disabled {
  .mat-form-field-outline {
    color: $ecab-secondary-color-light-2;
    background-color: $ecab-secondary-color-light-4;
  }
}
