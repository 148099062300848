/*
Ce fichier a pour vocation de donner un sens "métier" aux couleurs définies dans la charte graphique (colors.scss)

Exemples:
 - $ecab-red-900 est la couleur primaire de SIBAT
 - $ecab-red-1000 est la couleur secondaire de SIBAT
 - $ecab-orange est la couleur pour les "warnings" dans SIBAT
 - $ecab-green est la couleur pour les "validations" dans SIBAT
 - ...

 Dans ce fichier, vous pouvez ajouter des variables ayant un sens métier

*/

@import '/src/styles/colors/colors';

/* COULEURS PRIMAIRES */
$ecab-primary-color-dark-1: $ecab-red-1000;
$ecab-primary-color: $ecab-red-900;
$ecab-primary-color-light-1: $ecab-red-800;
$ecab-primary-color-light-2: $ecab-red-700;
$ecab-primary-color-light-3: $ecab-red-600;
$ecab-primary-color-light-4: $ecab-red-500;

/* COULEURS SECONDAIRES */
$ecab-secondary-color-dark: $ecab-black;
$ecab-secondary-color: $ecab-grey-1000;
$ecab-secondary-color-light-1: $ecab-grey-900;
$ecab-secondary-color-light-2: $ecab-grey-800;
$ecab-secondary-color-light-3: $ecab-grey-700;
$ecab-secondary-color-light-4: $ecab-grey-600;
$ecab-secondary-color-light-5: $ecab-grey-500;

$ecab-warning-color: $ecab-orange;
$ecab-warning-color-lighter: #{$ecab-orange}1f;
$ecab-validation-color: $ecab-green;
$ecab-validation-color-lighter: #{$ecab-green}1f;
$ecab-update-color: $ecab-yellow;

/* COULEUR DE TEXTE */
$ecab-text-color: $ecab-black;
$ecab-text-color-lighter: $ecab-grey-1000;

/* COULEUR DE BORDURE */
$ecab-border-color: $ecab-grey-700;

/* UTILISATION DES COULEURS */
$ecab-infos-generales-color: $ecab-grey-1000;
$ecab-assurance-color: $ecab-blue;
$ecab-prevention-color: $ecab-dark-green;
$ecab-intervention-color: $ecab-dark-green;
$ecab-finances-color: $ecab-orange;
$ecab-services-generaux-color: $ecab-green;

$typology-to-define-color: $ecab-blue;
$typology-green-color: $ecab-green;
$typology-green-color-lighter: #{$ecab-green}1f;
$typology-white-color: $ecab-secondary-color;
$typology-white-color-lighter: $ecab-secondary-color-light-4;
$typology-red-color: $ecab-primary-color;
$typology-red-color-lighter: $ecab-primary-color-light-4;
