$notification-info-level: $ecab-validation-color;
$notification-warning-level: $ecab-warning-color;

@mixin white-text-snackbar {
  .mat-simple-snackbar,
  button.mat-button {
    color: white;
  }
}

@mixin ecab-notification-theme($theme, $typography) {
  $background: map-get($theme, background);

  .notification-info {
    background-color: $notification-info-level;

    @include white-text-snackbar;
  }

  .notification-warning {
    background-color: $notification-warning-level;

    @include white-text-snackbar;
  }
}
