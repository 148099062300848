/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
*/
@use '@angular/material' as mat;

@use './colors/palettes' as palettes;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

@import 'variables';
@import 'tabs';
@import 'notifications';
@import 'utils/forms';
@import 'utils/lists';

// Fonts
$ecab-police: 'Verdana';
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');

$typography: mat.define-typography-config(
  $font-family: 'Roboto',
  $display-4: mat.define-typography-level(112px, 112px, 300, $letter-spacing: -0.0134em),
  $display-3: mat.define-typography-level(56px, 56px, 400, $letter-spacing: -0.0089em),
  $display-2: mat.define-typography-level(45px, 48px, 400, $letter-spacing: 0em),
  $display-1: mat.define-typography-level(34px, 40px, 400, $letter-spacing: 0.0074em),
  $headline: mat.define-typography-level(36px, 36px, 400, $letter-spacing: 0em),
  $title: mat.define-typography-level(21px, 21px, 500, $letter-spacing: 0.0075em),
  $subheading-2: mat.define-typography-level(21px, 21px, 400, $letter-spacing: 0.0094em),
  $subheading-1: mat.define-typography-level(18px, 18px, 400, $letter-spacing: 0.0067em),
  $body-2: mat.define-typography-level(14px, 24px, 500, $letter-spacing: 0.0179em),
  $body-1: mat.define-typography-level(14px, 20px, 400, $letter-spacing: 0.0179em),
  $button: mat.define-typography-level(14px, 14px, 500, $letter-spacing: 0.0893em),
  $caption: mat.define-typography-level(14px, 20px, 400, $letter-spacing: 0.0333em),
  $input: mat.define-typography-level(inherit, 1.125, 400, $letter-spacing: 1.5px),
);

// Compute font config
@include mat.core($typography);

// Override and customize the background and foreground colors
// ! This must be imported after including mat-core to work effectively
@import 'colors/background';
@import 'colors/foreground';

// Theme Config

// A mixin for ECAB specific themes
@mixin ecab-global-theme($theme, $typography) {
  @include ecab-tabs-theme($theme, $typography);
  @include ecab-notification-theme($theme, $typography);
}

$mat-primary: (
  main: $ecab-secondary-color-dark,
  lighter: $ecab-secondary-color-light-2,
  darker: $ecab-secondary-color-dark,
  200: $ecab-secondary-color-dark,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);

$mat-accent: (
  main: $ecab-secondary-color,
  lighter: $ecab-secondary-color-light-3,
  darker: $ecab-secondary-color,
  200: $ecab-secondary-color,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);

$mat-warn: (
  main: $ecab-warning-color,
  lighter: $ecab-warning-color-lighter,
  darker: $ecab-warning-color,
  200: $ecab-warning-color,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);

$ecab-light-theme: mat.define-light-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
  )
);

$ecab-red-palette: mat.define-palette(palettes.$ecab-red);
$ecab-red-theme: mat.define-light-theme(
  (
    color: (
      primary: $ecab-red-palette,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
  )
);

// Theme Init
@include mat.all-component-themes($ecab-light-theme);
@include ecab-global-theme($ecab-light-theme, $typography);

.red-theme {
  @include mat.all-component-colors($ecab-red-theme);
  @include ecab-global-theme($ecab-red-theme, $typography);
}

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-button,
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  padding: 0 1.15em;
  margin: 0 0.65em;
  min-width: 3em;
  line-height: 36.4px;

  .material-icons {
    font-size: 21px;
  }
}

.mat-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: 'Material Icons';
  .mat-badge-content {
    font-family: 'Roboto';
  }
}
