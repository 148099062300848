/**
/*  General purpose mixins
/**/

@use 'sass:map';

$color-adjustments: (
  lighten: true,
  darken: true,
);

@mixin clickable-element($background-color, $adjust-color: lighten, $adjust-amount: 10%) {
  @if not(map.has-key($color-adjustments, $adjust-color)) {
    @error 'Hover adjustment must be lighten | darken';
  }

  cursor: pointer;
  transition: background-color .4s ease-out;
  background-color: $background-color;
  &:hover {
    @if ($adjust-color == lighten) {
      background-color: lighten($background-color, $adjust-amount);
    } @else {
      background-color: darken($background-color, $adjust-amount);
    }
  }
}
