/*
Charte graphique myWEB

Code couleur défini à partir du fichier:
https://ecab.atlassian.net/wiki/spaces/MYECAB/pages/811302930/Couleurs

- Ce fichier a pour vocation de définir à un seul endroit toutes les couleurs de la charte graphique.
- Aucune autre couleur n'est censée être définie ailleurs dans l'application.
- Ces couleurs doivent être utilisées dans le fichier ecab.scss uniquement
- Ce fichier ne doit être édité qu'en cas de changement ou d'ajout de couleur dans la charte graphique.

*/

$ecab-red-1000: #8f1507;
$ecab-red-900: #e42313;
$ecab-red-800: #ff5d4a;
$ecab-red-700: #ff8d80;
$ecab-red-600: #ffbeb7;
$ecab-red-500: #ffefed;

$ecab-grey-1000: #575756;
$ecab-grey-900: #9b9b9b;
$ecab-grey-800: #bdbdbd;
$ecab-grey-700: #dfdfdf;
$ecab-grey-600: #f1f1f1;
$ecab-grey-500: #ffffff;

$ecab-orange: #eb7c00;
$ecab-green: #57830d;
$ecab-yellow: #e1931b;
$ecab-blue: #09698b;
$ecab-dark-green: #098b74;
$ecab-black: #333333;
